@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url(/ttf/Poppins-Thin.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(/ttf/Poppins-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url(/ttf/Poppins-Bold.ttf) format('truetype');
}